import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { Grid, Button } from "@mui/material";
import { deleteProperties, getProperty } from "../services/propertyService";
import {
  resetForm,
  setAssessorValue,
  setBuildingAddr,
  setCIDValue,
  setContact,
  setData,
  setOwnerMailing,
  setOwnerName,
  setParcelID,
  setPropertyDesc,
  setSearchStatus,
  setTransferID,
  setExempt,
  setMarker,
  setImportModal,
} from "../features/assessorSlice";
import { getCIDProperties } from "../services/cidService";
import { Box } from "@mui/system";
import {
  resetContacts,
  setResults,
  setSearchTerm,
  setSelectedContact,
  setTempData,
} from "../features/contactSlice";
import { getContact, getContacts } from "../services/contactService";
import { ImportExport } from "@mui/icons-material";

const PropertyTable = (props) => {
  const [selected, setSelected] = useState([]);
  const cid = useSelector((state) => state.assessor.value.form.cid);
  const data = useSelector((state) => state.assessor.value.data);
  const dispatch = useDispatch();

  const columns = [
    {
      field: "flag_color",
      width: 50,
      headerName: "Mark",
      renderCell: (params) => (
        <Box
          sx={{
            backgroundColor: params.value ? params.value : "gray",
            minWidth: "100%",
            borderRadius: 10,
          }}
        >
          &nbsp;
        </Box>
      ),
    },
    {
      field: "last_updated",
      headerName: "Last Updated",
      type: "date",
      width: 100,
      valueGetter: ({ value }) => value && new Date(value),
    },
    { field: "parcelID", headerName: "Parcel ID", width: 150 },
    { field: "owner_name", headerName: "Owner Name", width: 150 },
    { field: "owner_mailing", headerName: "Mailing Address", width: 150 },
    { field: "building_address", headerName: "Building Address", width: 150 },
    { field: "assessor_value", headerName: "Assessor Value", width: 150 },
    { field: "cid_value", headerName: "CID Value", width: 100 },
    { field: "property_desc", headerName: "Property Desc", width: 150 },

    { field: "name", headerName: "Name", width: 150 },
    { field: "phone_number", headerName: "Phone Number", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "company", headerName: "Company", width: 150 },
    { field: "secondary_contact", headerName: "Secondary Contact", width: 150 },
    { field: "secondary_phone", headerName: "Secondary Phone", width: 150 },
    { field: "secondary_email", headerName: "Secondary Email", width: 150 },
    { field: "notes", headerName: "Notes", width: 150 },
    { field: "callsMade", headerName: "Calls Made", width: 150, valueGetter: ({ value }) => value ? value : 0 },
    { field: "followUpCalls", headerName: "Follow Up Calls", width: 150, valueGetter: ({ value }) => value ? value : 0 },
    { field: "emailsSent", headerName: "Emails Sent", width: 150, valueGetter: ({ value }) => value ? value : 0 },
    { field: "dropIns", headerName: "Drops In", width: 150, valueGetter: ({ value }) => value ? value : 0 },
  ];

  const handleSelect = async (p) => {
    props.toggleLookup(true);
    if (p.length !== 0) {
      dispatch(resetContacts());
      dispatch(resetForm());
      setSelected(p);
      dispatch(setTransferID(p[p.length - 1]));
      await getProperty(p[p.length - 1]).then(async (property) => {
        dispatch(setParcelID(property.parcelID));
        dispatch(setOwnerName(property.owner_name));
        dispatch(setOwnerMailing(property.owner_mailing));
        dispatch(setCIDValue(property.cid_value));
        dispatch(setMarker(property.flag_color));
        dispatch(setBuildingAddr(property.building_address));
        dispatch(setPropertyDesc(property.property_desc));
        dispatch(setAssessorValue(property.assessor_value));
        if (property.contact_id) {
          dispatch(setContact(property.contact_id));
          const contactData = await getContact(property.contact_id);
          dispatch(setSearchTerm(contactData.name));
          dispatch(setSelectedContact(property.contact_id));
          dispatch(setTempData(contactData));
          const res = await getContacts(contactData.name);
          dispatch(setResults([res.find((c) => c.id === property.contact_id)]));
        }
        if (parseFloat(property.cid_value) === 0) {
          dispatch(setExempt(true));
        } else {
          dispatch(setExempt(false));
        }
        dispatch(setSearchStatus(2));
      });
    } else {
      dispatch(resetForm());
      dispatch(resetContacts());
    }
  };

  return (
    <Grid container sx={{ height: "1200px" }} mb={3}>
      <Grid item xs={12} sx={{ height: "1200px" }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          checkboxSelection
          onSelectionModelChange={(p) => handleSelect(p)}
          disableSelectionOnClick
          components={{
            Toolbar: () => {
              return cid ? (
                <GridToolbarContainer>
                  <Button
                    color="success"
                    startIcon={<ImportExport />}
                    onClick={() => dispatch(setImportModal(true))}
                  >
                    Import
                  </Button>
                  <GridToolbarExport />
                  <Button
                    color="error"
                    onClick={async () => {
                      deleteProperties(selected).then(async () => {
                        const refreshProperties = await getCIDProperties(cid);
                        dispatch(setData(refreshProperties.data));
                      });
                    }}
                  >
                    Delete Row
                  </Button>
                </GridToolbarContainer>
              ) : null;
            },
          }}
        />
      </Grid>

      {/* <Grid item> MOVE TO LOOKUP TOOL
        <Button sx={{ m: 1 }} variant="outlined">
          Import CSV
          <input style={{ opacity: "0", zIndex: 1, position: "absolute" }} type="file" accept=".csv" onChange={(e) => importFile(e.target.files[0])} />
        </Button>
      </Grid> */}
    </Grid>
  );
};

export default PropertyTable;
