import { api, getAuthHeader } from "../axiosConfig";

export const loginService = async (data) => {
  return await api
    .post("/auth/login", data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return false
    });
};

export const sendMagicLinkService = async (data) => {
  return await api
    .post("/auth/sendMagicLink", data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return false
    })
}

export const getUserService = async () => {
  return await api
    .get("/auth/getUserData", {
      headers: getAuthHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const getTeamService = async () => {
  return await api
    .get("/admin/getUsers", {
      headers: getAuthHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const logoutService = async (data) => {
  return await api
    .post("/auth/logout", data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
