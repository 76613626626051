import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Alert, Checkbox } from "@mui/material";
import {
  Button,
  CircularProgress,
  Grid,
  Input,
  InputAdornment,
  TextField,
  Typography,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import Contacts from "./contacts";
import { Box } from "@mui/system";
import ConfirmDelete from "./confirmDelete";
import { Minimize } from "@mui/icons-material";

import assessorSlice, {
  changeCID,
  setCIDS,
  setData,
  setContact,
  setParcelID,
  setOwnerName,
  setOwnerMailing,
  setBuildingAddr,
  setAssessorCIDValue,
  setExempt,
  setPropertyDesc,
  setSearchStatus,
  setTransferID,
  setCIDValue,
  resetForm,
  setMils,
  setCID,
  setMarker,
} from "../features/assessorSlice";
import { searchParcel } from "../services/lookupService";
import { addProperty } from "../services/lookupService";
import { useEffect, useState } from "react";
import CreateCID from "./createCID";
import {
  getCIDProperties,
  getCIDS,
  getCIDContacts,
  getCIDInfo,
} from "../services/cidService";
import { useDispatch, useSelector } from "react-redux";
import { updateProperty } from "../services/propertyService";
import { resetContacts } from "../features/contactSlice";
import { deleteCID } from "../services/cidService";
import ModifyCID from "./modifyCID";
import {
  changeName,
  changeNotes,
  loadLegend,
} from "../features/cidSlice";

const LookupTool = (props) => {
  const [dialogOpen, toggleDialog] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [confirmDialog, toggleConfirmDialog] = useState(false);
  const [modifyDialog, toggleModifyDialog] = useState(false);
  const searchStatus = useSelector(
    (state) => state.assessor.value.searchStatus
  );
  const mult = useSelector((state) => state.assessor.value.form.mils);
  const dispatch = useDispatch();
  const form = useSelector((state) => state.assessor.value.form);
  const assessor = useSelector((state) => state.assessor.value);
  const admin = useSelector((state) => state.auth.value.admin);

  const handleChangeCID = async (e) => {
    dispatch(setCID(e.target.value));
    setDataLoading(true);
    const cidData = await getCIDInfo(e.target.value);
    dispatch(changeNotes(cidData.notes));
    dispatch(changeName(cidData.name));
    dispatch(loadLegend(cidData.legend));
    const res = await getCIDProperties(e.target.value);
    const resContacts = await getCIDContacts(e.target.value);

    const contacts = [];

    if (resContacts) {
      resContacts.forEach((c) => {
        if (c !== null) {
          const { id, ...rest } = c;
          contacts.push(rest);
        }
      });
    }

    const joinedData = [];

    if (res.data) {
      if (res.data.length > 0) {
        res.data.forEach((p) => {
          if (p.contact_id) {
            const contactValue = contacts.find(
              (c) => c.contactTransferID === p.contact_id
            );
            joinedData.push({
              ...p,
              ...contactValue,
            });
          } else {
            joinedData.push({ ...p });
          }
        });
        setDataLoading(false);
      } else {
        setDataLoading(false);
      }
    }

    dispatch(setData(joinedData));

    assessor.cids.forEach((c) => {
      if (c.id === e.target.value) {
        dispatch(setMils(c.mils));
      }
    });
  };

  const onPush = async () => {
    if (form.transferID === -1) {
      addProperty(form).then(async () => {
        const refreshProperties = await getCIDProperties(form.cid);
        const resContacts = await getCIDContacts(form.cid);

        const contacts = [];
        if (resContacts) {
          resContacts.forEach((c) => {
            if (c !== null) {
              const { id, ...rest } = c;
              contacts.push(rest);
            }
          });
        }

        const joinedData = [];

        if (refreshProperties.data) {
          if (refreshProperties.data.length > 0) {
            refreshProperties.data.forEach((p) => {
              if (p.contact_id) {
                const contactValue = contacts.find(
                  (c) => c.contactTransferID === p.contact_id
                );
                joinedData.push({
                  ...p,
                  ...contactValue,
                });
              } else {
                joinedData.push({ ...p });
              }
            });
            setDataLoading(false);
          } else {
            setDataLoading(false);
          }
        }
        dispatch(setData(joinedData));
      });
    } else {
      updateProperty(form).then(async () => {
        const refreshProperties = await getCIDProperties(form.cid);
        const resContacts = await getCIDContacts(form.cid);

        const contacts = [];

        resContacts.forEach((c) => {
          if (c !== null) {
            const { id, ...rest } = c;
            contacts.push(rest);
          }
        });

        const joinedData = [];

        if (refreshProperties.data) {
          if (refreshProperties.data.length > 0) {
            refreshProperties.data.forEach((p) => {
              if (p.contact_id) {
                const contactValue = contacts.find(
                  (c) => c.contactTransferID === p.contact_id
                );
                joinedData.push({
                  ...p,
                  ...contactValue,
                });
              } else {
                joinedData.push({ ...p });
              }
            });
            setDataLoading(false);
          } else {
            setDataLoading(false);
          }
        }
        dispatch(setData(joinedData));
      });
    }
  };

  const handleDelete = async () => {
    toggleConfirmDialog(false);
    return new Promise((res) => {
      dispatch(resetForm());
      dispatch(changeCID({ cid: "", data: [] }));
      res(deleteCID(form.cid));
    })
      .then(async () => {
        const cids = await getCIDS();
        dispatch(setCIDS(cids));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = async (manualEntry) => {
    const { cid, parcelID } = form;
    if (cid === "") {
      dispatch(setSearchStatus(1));
    } else {
      if (manualEntry) {
        dispatch(setSearchStatus(2));
        dispatch(setOwnerName(""));
        dispatch(setOwnerMailing(""));
        dispatch(setBuildingAddr(""));
        dispatch(setPropertyDesc(""));
        dispatch(setContact(null));
        dispatch(resetContacts());
        dispatch(setAssessorCIDValue(0));
      } else {
        dispatch(setSearchStatus(1));
        const res = await searchParcel({
          cid: cid,
          parcelID: parcelID.trim().replaceAll("-", " "),
        });

        if (res) {
          dispatch(setSearchStatus(2));
          dispatch(setParcelID(res.parcelID));
          dispatch(setOwnerName(res.ownerName));
          dispatch(setOwnerMailing(res.ownerMailing));
          dispatch(setBuildingAddr(res.buildingAddress));

          if (form.transferID === -1) {
            dispatch(resetContacts());
            dispatch(setContact(null));
            dispatch(setPropertyDesc(""));
          }

          dispatch(setAssessorCIDValue(res.assessorInfo));
        } else {
          dispatch(setSearchStatus(2));
          dispatch(setOwnerName("NOT FOUND"));
          dispatch(setOwnerMailing("NOT FOUND"));
          dispatch(setBuildingAddr("NOT FOUND"));
          dispatch(setPropertyDesc(""));
          dispatch(setContact(null));
          dispatch(resetContacts());
          dispatch(setAssessorCIDValue(0));
        }
      }
    }
  };

  useEffect(() => {
    async function reqCIDS() {
      const res = await getCIDS();
      if (res) {
        dispatch(setCIDS(res));
      }
    }
    reqCIDS();
  }, []);

  return (
    <Grid
      container
      spacing={3}
      textAlign="center"
      p={1}
      justifyContent="center"
    >
      <CreateCID dialogOpen={dialogOpen} handleClose={toggleDialog} />
      <ConfirmDelete
        dialogOpen={confirmDialog}
        handleClose={toggleConfirmDialog}
        deleteCID={handleDelete}
      />
      <ModifyCID dialogOpen={modifyDialog} handleClose={toggleModifyDialog} />
      <Grid item xs={12} mx="auto">
        <Button
          fullWidth
          variant="outlined"
          color="inherit"
          onClick={() => props.toggleLookup()}
        >
          <Minimize />
        </Button>
      </Grid>
      <Grid item xs={4} mx="auto">
        <Button
          variant="outlined"
          color="success"
          onClick={() => toggleDialog(true)}
        >
          CREATE CID
        </Button>
      </Grid>
      {admin && form.cid ? (
        <>
          <Grid item xs={4} mx="auto">
            <Button
              variant="outlined"
              color="warning"
              onClick={() => toggleModifyDialog(true)}
            >
              Modify CID
            </Button>
          </Grid>
          <Grid item xs={4} mx="auto">
            <Button
              variant="outlined"
              color="error"
              onClick={() => toggleConfirmDialog(true)}
            >
              Delete CID
            </Button>
          </Grid>
        </>
      ) : (
        <></>
      )}

      {!assessor.form.cid && searchStatus === 2 ? (
        <Alert sx={{ mt: 1 }} severity="error">
          Must select a CID
        </Alert>
      ) : (
        <></>
      )}

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>CID</InputLabel>
          <Select
            value={form.cid}
            onChange={(e) => handleChangeCID(e)}
            label="CID"
            fullWidth
          >
            {assessor.cids !== null
              ? assessor.cids.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element.id}>
                      {element.name}
                    </MenuItem>
                  );
                })
              : null}
          </Select>
        </FormControl>
      </Grid>
      {dataLoading ? (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      ) : (
        <></>
      )}

      <Grid item xs={12}>
        <TextField
          value={form.parcelID}
          fullWidth
          label="Parcel ID"
          onChange={(e) => dispatch(setParcelID(e.target.value))}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(resetForm());
          }}
        >
          Clear
        </Button>
      </Grid>
      <Grid item xs={5}>
        <Button
          variant="contained"
          onClick={() => onSubmit(true)}
          type="submit"
          color="warning"
        >
          Manual Entry
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button variant="contained" onClick={() => onSubmit()} type="submit">
          Search
        </Button>
      </Grid>

      {searchStatus === 1 ? (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      ) : (
        <></>
      )}
      {searchStatus === 2 ? (
        <>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="markerColor">Marker Color</FormLabel>

              <Select
                fullWidth
                labelId="markerColor"
                value={form.flagColor}
                onChange={(e) => dispatch(setMarker(e.target.value))}
              >
                <MenuItem value="blue">
                  <Box
                    sx={{
                      backgroundColor: "blue",
                      minWidth: "100%",
                      borderRadius: 10,
                    }}
                  >
                    &nbsp;
                  </Box>
                </MenuItem>
                <MenuItem value="green">
                  <Box
                    sx={{
                      backgroundColor: "green",
                      minWidth: "100%",
                      borderRadius: 10,
                    }}
                  >
                    &nbsp;
                  </Box>
                </MenuItem>
                <MenuItem value="red">
                  <Box
                    sx={{
                      backgroundColor: "red",
                      minWidth: "100%",
                      borderRadius: 10,
                    }}
                  >
                    &nbsp;
                  </Box>
                </MenuItem>
                <MenuItem value="orange">
                  <Box
                    sx={{
                      backgroundColor: "orange",
                      minWidth: "100%",
                      borderRadius: 10,
                    }}
                  >
                    &nbsp;
                  </Box>
                </MenuItem>
                <MenuItem value={null}>
                  <Box
                    sx={{
                      backgroundColor: "gray",
                      minWidth: "100%",
                      borderRadius: 10,
                    }}
                  >
                    &nbsp;
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => dispatch(setOwnerName(e.target.value))}
              fullWidth
              label="Owner Name"
              value={form.ownerName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => dispatch(setOwnerMailing(e.target.value))}
              fullWidth
              label="Owner Mailing Address"
              value={form.ownerMailing}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => dispatch(setBuildingAddr(e.target.value))}
              fullWidth
              label="Building Address"
              value={form.buildingAddress}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="Exempt from tax"
              control={
                <Checkbox
                  color="error"
                  checked={form.exempt}
                  onChange={() => dispatch(setExempt(!form.exempt))}
                />
              }
            />
          </Grid>
          {form.exempt ? (
            <></>
          ) : (
            <>
              <Grid item xs={12}>
                <FormControl>
                  <InputLabel>Assessor Value</InputLabel>
                  <Input
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    type="tel"
                    fullWidth
                    onChange={(e) => {
                      if (!e.target.value) {
                        dispatch(setAssessorCIDValue(0));
                      } else {
                        dispatch(
                          setAssessorCIDValue(parseFloat(e.target.value))
                        );
                      }
                    }}
                    value={form.assessorValue}
                    label="Assessor Value"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  CID Value: $ {form.cidValue.toFixed(2)}
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={12} mx="auto">
            <TextField
              minRows={7}
              multiline
              fullWidth
              onChange={(e) => dispatch(setPropertyDesc(e.target.value))}
              label="Property Description"
              value={form.propertyDesc}
            />
          </Grid>

          <Grid item xs={12}>
            <Contacts />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color={form.transferID === -1 ? "primary" : "warning"}
              onClick={() => onPush()}
              disabled={!form.parcelID}
            >
              {form.transferID === -1
                ? "Add Property Data"
                : "Update Property Data"}
            </Button>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default LookupTool;
