import { api } from "../axiosConfig";


export async function refreshTokenService() {
    return await api.post("/auth/refreshToken", {}, { withCredentials: true }).then((res) => {
        return res.data;
    }).catch((err) => {
        return err
    })
}
